<template>
  <div class="coupon">
    <el-card class="coupon-card" shadow="hover">
      <div class="card-top">
        <p class="card-price">¥{{ item.amount }}</p>
        <p v-if="item.type == 2" class="card-info">{{ item.params.summary }}</p>
        <p v-else class="card-info" style="visibility: hidden">{{item.status}}</p>
        <p class="card-data">
          {{ item.startTime.split(" ")[0] }} -
          {{ item.endTime.split(" ")[0] }}
        </p>
      </div>
      <div class="card-bottom">
        <p>
          限品类：<a class="card-span">{{ item.typeLabel }}</a>
        </p>
        <p>
          券编号：<a class="card-span">{{ item.couponPeopleId }}</a>
        </p>
        <el-button class="card-btn" @click="usenow(item.couponPeopleId)" round
          >立即使用</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    usenow(id) {
      console.log("我被点击了", id);
      this.$router.push("/prolist");
    },
  },
};
</script>

<style scoped>
/deep/ .el-card__body {
  padding: 0 !important;
}
.coupon {
  display: flex;
  padding-right: 12px;
  padding-bottom: 10px;
}
.coupon-card {
  width: 280px;
  height: 360px;
}
.card-top {
  height: 180px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background: url("~@/assets/img/card.png") no-repeat;
}
.card-price {
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
}
.card-info {
  color: #ffffff;
}
.card-data {
  color: #217fba;
}
.card-bottom {
  display: flex;
  height: 180px;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: space-around;
}
.card-span {
  font-size: 6px;
  color: #b3b3b3;
}
.el-button {
  width: 200px;
  height: 40px;
}
</style>
