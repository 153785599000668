<template>
  <div>
    <div class="notice-body">
      <ul class="notice-list">
        <li>
          <p>我的优惠券</p>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane class="coupon-all" label="全部优惠券" name="all">
              <Coupon
                :item="item"
                v-for="(item, index) in counponList"
                :key="index"
              />
              <Timecoupon
                :item="item"
                v-for="(item, index) in timecounponList"
                :key="index"
              />
              <Badcoupon
                :item="item"
                v-for="(item, index) in badcounponList"
                :key="index"
              />
              <div
                class="coupon-text"
                v-if="counponList == '' && badcounponList == ''"
              >
                <h3>暂无优惠券</h3>
              </div>
            </el-tab-pane>
            <el-tab-pane class="coupon-all" label="可用优惠券" name="available">
              <Coupon
                :item="item"
                v-for="(item, index) in counponList"
                :key="index + 'a'"
              />
              <Timecoupon
                :item="item"
                v-for="(item, index) in timecounponList"
                :key="index"
              />
              <div class="coupon-text" v-if="counponList == ''">
                <h3>暂无优惠券</h3>
              </div>
            </el-tab-pane>
            <el-tab-pane class="coupon-all" label="已使用/失效" name="fail">
              <Badcoupon
                :item="item"
                v-for="(item, index) in badcounponList"
                :key="index + 'b'"
              />
              <div class="coupon-text" v-if="badcounponList == ''">
                <h3>暂无优惠券</h3>
              </div>
            </el-tab-pane>
          </el-tabs>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getCouponList } from "@/api/all.js";
import Coupon from "@/components/Coupon/coupon.vue";
import Badcoupon from "@/components/Coupon/badcoupon.vue";
import Timecoupon from "@/components/Coupon/timecoupon.vue";
export default {
  components: { Timecoupon, Coupon, Badcoupon },
  data() {
    return {
      activeName: "all",
      allcounponList: [],
      badcounponList: [],
      counponList: [],
      timecounponList: [],
    };
  },
  created() {
    this.getcouponList();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getcouponList() {
      getCouponList().then((res) => {
        this.allcounponList = res.data;
        console.log(res.data);
        this.allcounponList.map((item) => {
          if (item.status === "NORMAL") {
            this.counponList.push(item);
          } else if (item.status === "NOT_STARTED") {
            this.timecounponList.push(item);
          } else {
            this.badcounponList.push(item);
          }
        });
      });
    },
  },
};
</script>

<style>
.coupon-text {
  width: 100%;
  height: 300px;
}
.coupon-text > h3 {
  text-align: center;
  line-height: 400px;
  color: #666666;
}
.coupon-all {
  display: flex;
  flex-wrap: wrap;
}
.notice-body {
  /*height: 811px;*/
  min-height: 500px;
}
.notice-body li {
  border-bottom: none;
  margin: 0px;
  padding: 0px;
}
p {
  margin: 0px;
  padding: 0px;
}
.el-tabs__item.is-active {
  color: #b60005;
}
.el-tabs__item:hover {
  color: #b60005;
}
.el-tabs__active-bar {
  background-color: #b60005;
}
</style>
